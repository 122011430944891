import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';

const Review = props => {
  const { review, intl, isSingleReview } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div>
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>
          {/* {review.attributes.content} */}
          {review.author.attributes.profile?.displayName}
        </p>
        {isSingleReview &&
          review.author.attributes.profile.publicData?.designation ? <p className={css.reviewContent}>
          {review.author.attributes.profile.publicData?.designation}
        </p>
          : null}
        <p className={css.reviewContentDetails}>
          {review.attributes.content}

        </p>
        <p className={css.reviewInfo}>
          {/* <UserDisplayName user={review.author} intl={intl} /> */}
          {/* <span className={css.separator}>•</span> */}
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
          {/* {!isSingleReview ? dateString : null}
          <span className={css.desktopSeparator}>•</span> */}
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, isProfilePage, index, isSingleReview } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    isSingleReview ?
      reviews?.length &&
      <div key={`Review_${reviews[index].id.uuid}`} className={classNames(css.reviewItem, css.reviewItemConsult)}>
        <Review review={reviews[index]} intl={intl} isSingleReview={isSingleReview} />
      </div>

      : !isProfilePage ?
        <ul className={classes}>
          {reviews?.length &&
            reviews.map(r => {
              return (
                <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
                  <Review review={r} intl={intl} />
                </li>
              );
            })}
        </ul>
        :
        reviews?.length &&
        <div key={`Review_${reviews[index].id.uuid}`} className={classNames(css.reviewItem, css.reviewItemProfile)}>
          <Review review={reviews[index]} intl={intl} />
        </div>



  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
