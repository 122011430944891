import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Button,
  IconCollection,
  OutsideClickHandler,
} from '../../components';

import css from './Footer.module.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { BOOK_MODAL, CHAT_MODAL, USER_ROLE_EXPERT, FOOTER_PAGE, CANCEL_TAB } from '../../util/types';
import TawkTo from '../../containers/LandingPage/Tawk';
import Calendly from '../../containers/LandingPage/Calendly';

const renderSocialMediaLinks = (intl, config) => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, isAuthenticated } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const config = useConfiguration();
  const { rootClassName, className, intl, isAuthenticated, currentUser } = props;
  const { role } = (!!currentUser?.id && currentUser?.attributes?.profile.publicData) || {};
  const emailUnverified = !!currentUser?.id && !currentUser.attributes.emailVerified;

  const socialMediaLinks = renderSocialMediaLinks(intl, config);
  const classes = classNames(rootClassName || css.root, className);
  const [openModal, setOpenModal] = useState(null);

  const handleButtonClick = () => {
    if (openModal === CHAT_MODAL) {
      setOpenModal(null);
    } else {
      setOpenModal(CHAT_MODAL);
    }
  };

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.socialLinksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="footer" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescriptionText" />
                </p>
                {/* <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p> */}
              </div>
            </div>
            <div className={css.infoLinks}>
              <h4 className={css.footerLinkTitle}><FormattedMessage id="Footer.quickLink" /></h4>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.toHomePage" />
                  </NamedLink>
                </li>
                {role === USER_ROLE_EXPERT ? null : (
                  <li className={css.listItem}>
                    <NamedLink
                      name={isAuthenticated ? "NewListingPage" : "SignupPage"}
                      className={emailUnverified ? classNames(css.link, css.pointerEvents) : classNames(css.link)}
                      to={{ client: !isAuthenticated ? true : null }}
                    >
                      <FormattedMessage id="Footer.toNewListingPage" />
                    </NamedLink>
                  </li>
                )}
                <li className={css.listItem}>
                  {/* <NamedLink name="DashboardPage" className={!isAuthenticated || emailUnverified ? classNames(css.link, css.pointerEvents): css.link } params={{ tab: CANCEL_TAB }}>
                    <FormattedMessage id={role === USER_ROLE_EXPERT ? "Footer.toSearchPage1" : "Footer.toSearchPage"} />
                  </NamedLink> */}
                 <NamedLink name="PrivacyPolicyPage" className={css.link}>
               <span> <FormattedMessage id={"Footer.privacyPolicy"} /></span>
              </NamedLink>
                </li>
                {!isAuthenticated ? (
                  <li className={css.listItem}>
                    <NamedLink name="SignupPage" className={css.link}>
                      <FormattedMessage id={"Footer.toSignupPage"} />
                    </NamedLink>
                  </li>
                ) : null}
              </ul>
            </div>

            <div className={css.searchesExtra}>
              <h4 className={css.footerLinkTitle}><FormattedMessage id="Footer.ContactHeading" /></h4>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <a href='mailto:support@taxable.online' className={css.link}><FormattedMessage id="Footer.emailText" /></a>
                  {/* <NamedLink name="SearchPage" className={classNames(css.link, css.pointerEvents)}>
                    <FormattedMessage id="Footer.Contact1" />
                  </NamedLink> */}
                </li>
                {/* <li className={css.listItem}>
                  <span className={css.link} onClick={() => setOpenModal(BOOK_MODAL)}>
                    <FormattedMessage id="Footer.Contact2" />
                  </span>
                </li> */}
                <li className={css.listItem}>
                  <span className={css.link} onClick={() => handleButtonClick(CHAT_MODAL)}>
                    <FormattedMessage id="Footer.Contact3" />
                  </span>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <h4 className={classNames(css.footerLinkTitle, css.footerLinkTitle2)}><FormattedMessage id="Footer.joinWithUs" /></h4>
              {/* {socialMediaLinks.length > 0 ? (
                <div className={css.socialLinks}>{socialMediaLinks}</div>
              ) : null} */}
              <div className={css.socialLinks}>
                <ul>
                  {/* <li>
                    <IconCollection name="ICON_FACEBOOK" />
                  </li>
                  <li>
                    <IconCollection name="ICON_TWITTER" />
                  </li> */}
                  <li>
                    <IconCollection name="ICON_LINKEDIN" />
                  </li>
                  <li>
                    <IconCollection name="ICON_INSTA" />
                  </li>
                  <li>
                    <IconCollection name="ICON_YOUTUBE" />
                  </li>
                </ul>
              </div>
              {!isAuthenticated ? (
                <div>
                  <NamedLink
                    name="SignupPage"
                    to={{ client: true }}
                    className={css.submitProjectBtn}
                  >
                    <FormattedMessage id="Footer.submitProject" />
                  </NamedLink>
                </div>
              ) : null}
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <span className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </span>
            {/* <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div> */}
          </div>
        </div>
      </div>
      {openModal === CHAT_MODAL ? (
        <TawkTo widgetId={process.env.REACT_APP_TAWK_PROPERTY_AND_WIDGET_ID} />
      ) : openModal === BOOK_MODAL ? (
        <OutsideClickHandler onOutsideClick={() => setOpenModal(null)}>
          <Calendly pageName={FOOTER_PAGE} />
        </OutsideClickHandler>
      ) : null}
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;
  return {
    isAuthenticated,
    currentUser,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps)
)(Footer);
