import React, { Component, useEffect, useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { CANCEL_TAB, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import { fetchUserTransactions } from '../../containers/DashboardPage/DashboardPage.duck';
import { useDispatch } from 'react-redux';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const dispatch = useDispatch();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    params,
  } = props;
  const [isUserHasTransaction, setIsUserHasTransaction] = useState(0);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { projectType, interimRole, industryKnowledge, softwareKnowledge } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const expertListingProfile = currentListing?.id && currentListing.author.profileImage

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = expertListingProfile
    ? Object.keys(expertListingProfile?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  useEffect(() => {
    dispatch(fetchUserTransactions(author.id)).then((r) => {
      setIsUserHasTransaction(r.data.data.length)
    })
  }, [params.tab === CANCEL_TAB])

  return (
    <>
      {isUserHasTransaction > 0 ?
        <NamedLink className={classes} name="ProfilePage" params={{ id: author?.id?.uuid }}>
          <div className={css.cardMain}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={expertListingProfile}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
            <div className={css.info}>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
                {/* {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
            <div className={css.perUnit}>
              <FormattedMessage
                id="ListingCard.perUnit"
                values={{ unitType: publicData?.unitType }}
              />
            </div>
          ) : null} */}
              </div>
              <div className={css.mainInfo}>
                {/* <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div> */}
                {showAuthorInfo ? (
                  <div className={css.authorInfo}>
                    <FormattedMessage id="ListingCard.author" values={{ authorName: authorName?.split(" ")[0] }} />
                  </div>
                ) : null}
                <div>
                  <p className={css.subTitle}><FormattedMessage id="ListingCard.projectType" /></p>
                  <div className={css.pillsWrapper}>
                    {projectType?.length &&
                      projectType.map(e => {
                        return <span key={e.key} className={css.pills}>{e.label}</span>;
                      })}
                  </div>

                </div>
                <div>
                  <p className={css.subTitle}><FormattedMessage id="ListingCard.interimRole" /></p>
                  <div className={css.pillsWrapper}>
                    {interimRole?.length &&
                      interimRole.map(e => {
                        return <span key={e.key} className={css.pills}>{e.label}</span>;
                      })}
                  </div>

                </div>
                <div>
                  <p className={css.subTitle}><FormattedMessage id="ListingCard.software" /></p>
                  <div className={css.pillsWrapper}>
                    {softwareKnowledge?.length &&
                      softwareKnowledge.map(e => {
                        return <span key={e.key} className={css.pills}>{e.label}</span>;
                      })}
                  </div>
                </div>
                {/* <div>
              <p className={css.subTitle}><FormattedMessage id="ListingCard.industry" /></p>
              <div className={css.pillsWrapper}>
                {industryKnowledge?.length &&
                  industryKnowledge.map(e => {
                    return <span key={e.key} className={css.pills}>{e.label}</span>;
                  })}
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </NamedLink>
        : null
      }
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
