import moment from "moment";
import { FormattedMessage } from "react-intl";
import { txt, txtVat } from "../config/configListing";
import { getAllTransitionsForEveryProcess, MILESTONE_PROCESS_NAME, PROPOSAL_PROCESS_NAME } from "../transactions/transaction";
import { transitions } from "../transactions/transactionProcessBooking";
import { ACTIVE_TAB, COMPLETED_TAB, LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_HOUR, LINE_ITEM_VAT, LINE_ITEM_VAT_PLATEFORM, MESSAGE_TAB, PROPOSAL_PENDING, PROPOSAL_TAB, USER_ROLE_CLIENT } from "./types";

export const getUserRole = user => user?.id && user?.attributes?.profile?.publicData?.role;

export const getUserVerify = user => user?.id && user?.attributes?.emailVerified;

export const getUserDetails = user => {
  const fullName =
    !!user?.id && user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
      ? user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
      : user?.attributes?.profile?.publicData?.fullName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  return {
    fullName,
    profileImage,
    email,
    id,
  };
};

export const getCommissionAmountWithVAT = (lineItems) => {
  // Find the line items with VAT
  const vatLineItems = lineItems?.filter(item => item?.code === LINE_ITEM_VAT_PLATEFORM);
  const vatAmounts = vatLineItems?.map(item => item?.lineTotal?.amount / 100);

  // Find the line item with customer commission and VAT
  const commissionLineItem = lineItems?.find(item => item?.code === LINE_ITEM_CUSTOMER_COMMISSION);
  const commissionAmountWithVAT = commissionLineItem?.lineTotal?.amount / 100 + vatAmounts?.reduce((a, b) => a + b, 0);
  return commissionAmountWithVAT.toFixed(2);
}

export const getPriceAmountWithVAT = (lineItems) => {
  // Find the line items with VAT
  const vatLineItems = lineItems?.filter(item => item?.code === LINE_ITEM_VAT);
  const vatAmounts = vatLineItems?.map(item => item?.lineTotal?.amount / 100);

  // Find the line item with hour and VAT
  const priceLineItem = lineItems?.find(item => item?.code === LINE_ITEM_HOUR);
  const priceAmountWithVAT = priceLineItem?.lineTotal?.amount / 100 + vatAmounts?.reduce((a, b) => a + b, 0);
  return priceAmountWithVAT.toFixed(2);
}

export const getVatText = (expertCountry, clientCountry) => {
  const result = txt.find((st) => st.clientCountry === clientCountry && st.expertCountry === expertCountry);
  return result ? result.note : null;
}

export const getVatServiceText = (clientCountry) => {
  const result = clientCountry !== 'NL';
  return result ? "VAT reverse charge" : null;
}

export const getLastTransitions = (params) => {
  const { tab } = params;

  switch (tab) {
    case MESSAGE_TAB:
      return [
        transitions.INQUIRE,
        transitions.COMPLETE,
        transitions.PROPOSAL_CANCEL,
        transitions.PROPOSAL_UPDATE,
        transitions.REQUEST_PROPOSAL,
        transitions.PROPOSAL_DECLINE,
        transitions.PROPOSAL_ACCEPTED,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.EXPIRE_REVIEW_PERIOD,
        transitions.TRANSITION_CREATE_MILESTONE,
        transitions.TRANSITION_UPDATE_MILESTONE,
        transitions.TRANSITION_DELETE_MILESTONE,
        transitions.PROPOSAL_ACCEPTED_BY_EXPERT,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT,
        transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT,
        transitions.REQUEST_PROPOSAL_AFTER_DECLINE,
        transitions.PROPOSAL_UPDATE_REQUEST_DECLINE,
        transitions.PROPOSAL_UPDATE_REQUEST_BY_CLIENT,
        transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE,
        transitions.TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT
      ];
    case ACTIVE_TAB:
      return [
        transitions.PROPOSAL_ACCEPTED,
        transitions.TRANSITION_CREATE_MILESTONE,
        transitions.TRANSITION_UPDATE_MILESTONE,
        transitions.TRANSITION_DELETE_MILESTONE
      ];
    case PROPOSAL_TAB:
      return [
        transitions.REQUEST_PROPOSAL,
        transitions.PROPOSAL_ACCEPTED_BY_EXPERT,
        transitions.PROPOSAL_UPDATE,
        transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT,
        transitions.PROPOSAL_UPDATE_REQUEST_BY_CLIENT,
        transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT,
        transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE,
        transitions.REQUEST_PROPOSAL_AFTER_DECLINE,
      ];
    case COMPLETED_TAB:
      return [
        transitions.COMPLETE,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.EXPIRE_REVIEW_PERIOD,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.AUTO_COMPLETE,
        transitions.TRANSITION_COMPLETE_PROJECT_BY_OPERATOR
      ];
    default:
      return null;
  }
};

export const getTransitionsStatus = (tx, processState, processName, transactionRole, currentUser) => {

  if (processState === PROPOSAL_PENDING && getUserRole(currentUser) === USER_ROLE_CLIENT) {
    if (tx.attributes.lastTransition === transitions.REQUEST_PROPOSAL ||
      tx.attributes.lastTransition === transitions.REQUEST_PROPOSAL_AFTER_DECLINE ||
      tx.attributes.lastTransition === transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE) {
      return <FormattedMessage id="DashboardCard.status" />;
    } else if (tx.attributes.lastTransition === transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT) {
      return <FormattedMessage id="DashboardCard.status1" />;
    } else if (tx.attributes.lastTransition === transitions.PROPOSAL_UPDATE) {
      return <FormattedMessage id="DashboardCard.status0" />;
    }
  } else if (
    (tx.attributes.lastTransition === transitions.TRANSITION_CREATE_MILESTONE ||
      tx.attributes.lastTransition === transitions.TRANSITION_UPDATE_MILESTONE) &&
    !tx.attributes.metadata.isPaid &&
    !tx.attributes.metadata.isValidated
  ) {
    return <FormattedMessage id="DashboardCard.status2" />;
  } else if (
    tx.attributes.lastTransition === transitions.TRANSITION_DELETE_MILESTONE &&
    !tx.attributes.metadata.isPaid &&
    !tx.attributes.metadata.isValidated
  ) {
    return <FormattedMessage id="DashboardCard.status3" />;
  } else if (tx.attributes.metadata.isPaid && processState === 'accepted' && !tx.attributes.metadata.isValidated && !tx.attributes.metadata.isCompleted) {
    return <FormattedMessage id="DashboardCard.status4" />;
  } else if (tx.attributes.metadata.isValidated && processState === 'accepted') {
    return <FormattedMessage id="DashboardCard.status5" />;
  } else if (tx.attributes.metadata.isCompleted && tx.attributes.metadata.isPaid) {
    return <FormattedMessage id="DashboardCard.status6" />;
  } else {
    return (
      <FormattedMessage
        id={`InboxPage.${processName}.${processState}.status`}
        values={{ transactionRole }}
      />
    );
  }

  // Use the "statusMessage" variable wherever you need to display the message

}

export const getFilteredTransitions = (transaction) => {
  const lastTransition = transaction?.attributes?.lastTransition;
  const isProposalRequestTransition = [
    transitions.REQUEST_PROPOSAL,
    transitions.PROPOSAL_UPDATE,
  ]?.includes(lastTransition);
  const isDeclineTransition = [transitions.PROPOSAL_DECLINE]?.includes(lastTransition);
  const isUpdateTransition = [transitions.REQUEST_PROPOSAL_AFTER_DECLINE,
  transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE]?.includes(lastTransition);
  const isDeclineTransitions = [transitions.PROPOSAL_UPDATE_REQUEST_DECLINE]?.includes(lastTransition);
  const isProposalUpdateLastTransition = [transitions.PROPOSAL_UPDATE_REQUEST_BY_CLIENT]?.includes(lastTransition);

  switch (true) {
    case isProposalRequestTransition:
      return transitions.PROPOSAL_UPDATE;
    case isDeclineTransition:
      return transitions.REQUEST_PROPOSAL_AFTER_DECLINE;
    case isUpdateTransition:
      return transitions.PROPOSAL_UPDATE;
    case isDeclineTransitions:
      return transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE;
    case isProposalUpdateLastTransition:
      return transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT;
    default:
      return transitions.REQUEST_PROPOSAL;
  }
};

const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.target = '_blank'
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFiles = (url, name) => {
  const pdfFileUrl = url;
  const pdfFileName = name;
  return downloadFile(pdfFileUrl, pdfFileName);

};


export const filteredTransactions = tx => {

  // const chainedTransactionWithBriefProcess = tx?.filter(
  //   item =>
  //     item?.attributes?.protectedData?.chainedTransactionId &&
  //     item?.attributes?.processName === TRANSACTION_BRIEF_PROCESS
  // );

  // const chainedTransactionWithoutBriefProcess = tx?.filter(
  //   item =>
  //     !item?.attributes?.protectedData?.chainedTransactionId &&
  //     item?.attributes?.processName === TRANSACTION_BRIEF_PROCESS
  // );

  const chainedTransactionWithoutPropsalProcess = tx?.filter(
    item => {
      if (item?.attributes?.protectedData?.previoustxId && item?.attributes?.lastTransition === "transition/proposal-accept") {

        return item?.attributes?.protectedData?.previoustxId &&
          item?.attributes?.processName === MILESTONE_PROCESS_NAME
      } else {
        return item?.attributes?.processName === PROPOSAL_PROCESS_NAME
      }
    }
  );
  // const briefTransactionWithChainTransaction = tx?.filter(item => {
  //   if(item?.attributes?.processName === MILESTONE_PROCESS_NAME){
  //     return !item?.attributes?.protectedData?.chainedTransaction && item
  //   }
  // })

  return chainedTransactionWithoutPropsalProcess.sort(
    (a, b) =>
      moment(b.attributes.lastTransitionedAt).unix() -
      moment(a.attributes?.lastTransitionedAt).unix()
  );
};

const vatArray = [
  { country: 'LU', vat: 16 },
  { country: 'DE', vat: 19 },
  { country: 'GB', vat: 20 },
  { country: 'NL', vat: 21 },
  { country: 'BE', vat: 21 },
];

export const getVatPercentage = (expertCountry, clientCountry) => {
  const result = vatArray.find((st) => st.country === clientCountry && st.country === expertCountry);
  return result ? result.vat : 0;
};

export const getVatCommissionPercentage = (clientCountry) => {
  if ('NL' === clientCountry) {
    return 21;
  } else {
    return 0;
  };
};


export const getMeetingStatus = tx => {
  const { videoScheduleData = {} } = tx?.attributes?.metadata;
  const { status } = videoScheduleData;
  return status ?? null;
}

export const validateStep = (values, USER_SUPPORT_TYPE) => {
  const selectedStep = values?.selectedStep;

  switch (selectedStep) {
    case 1:
      return !values.companyType;
    case 2:
      return !values.supportType;
    case 3:
      return !(values.countrySupport && values.countrySupport.length > 0);
    case 4:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !(values.interimRole && values.interimRole.length > 0);
      } else {
        return !(values.projectType && values.projectType.length > 0);
      }
    case 5:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !(values.projectType && values.projectType.length > 0);
      } else {
        return !(values.industryKnowledge && values.industryKnowledge.length > 0);
      }
    case 6:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !(values.industryKnowledge && values.industryKnowledge.length > 0);
      } else {
        return !values.expectedDuration;
      }
    case 7:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !values.expectedDuration;
      } else {
        return !values.projectDetails;
      }
    case 8:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !values.projectDetails;
      } else {
        return !values.projectName;
      }
    case 9:
      if (values.supportType !== USER_SUPPORT_TYPE) {
        return !values.projectName;
      } else {
        return !values.termsAndConditions;
      }
    case 10:
      return !values.termsAndConditions;
    default:
      return false;
  }
}