import React from 'react';
import { InlineWidget } from 'react-calendly';
import css from './LandingPage.module.css';
import { FOOTER_PAGE } from '../../util/types';
const Calendly = props => {
  const { pageName } = props;
  const styles = {
    border: '1px solid #3A21E2',
    height: '53vh',
    width: '40vw',
    padding: '1rem',
    overflow: 'hidden',
    background: 'rgba( 255, 255, 255, 0.25 )',
    boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
    backdropFilter: ' blur( 10px )',
    borderRadius: '10pxm',
    marginTop: pageName === FOOTER_PAGE ? '-30rem' : '0px',
  };

  return (
    <div className={css.calendly}>
      <InlineWidget
        // url="https://calendly.com/deepak-kaushal-dev"
        url={process.env.REACT_APP_CALENDLY_URL}
        styles={styles}
      />
    </div>
  );
};

export default Calendly;
