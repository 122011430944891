import React from 'react';

const IconPasswordVisible = props => {
  const { passFunction } = props;
  return (
    <svg
      onClick={passFunction}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'transparent' }}
    >
      <path
        d="M12.1083 7.8916L7.89166 12.1083C7.35 11.5666 7.01666 10.8249 7.01666 9.99993C7.01666 8.34993 8.35 7.0166 10 7.0166C10.825 7.0166 11.5667 7.34994 12.1083 7.8916Z"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.85 4.8084C13.3917 3.7084 11.725 3.1084 10 3.1084C7.05834 3.1084 4.31667 4.84173 2.40834 7.84173C1.65834 9.01673 1.65834 10.9917 2.40834 12.1667C3.06667 13.2001 3.83334 14.0917 4.66667 14.8084"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01666 16.2751C7.96666 16.6751 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1584 17.5917 12.1584C18.3417 10.9834 18.3417 9.0084 17.5917 7.8334C17.3167 7.40006 17.0167 6.99173 16.7083 6.6084"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.925 10.583C12.7083 11.758 11.75 12.7163 10.575 12.933"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89167 12.1084L1.66667 18.3334"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 1.66699L12.1083 7.89199"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconPasswordVisible;
