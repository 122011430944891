import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  getAccessToken,
  scheduleGoogleMeeting,
  getRefreshToken,
} from '../../util/api';
import { storableError } from '../../util/errors';
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const CREATE_MEETING_REQUEST = 'app/GoogleRedirectPage/CREATE_MEETING_REQUEST';
export const CREATE_MEETING_SUCCESS = 'app/GoogleRedirectPage/CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_ERROR = 'app/GoogleRedirectPage/CREATE_MEETING_ERROR';
export const CREATE_REFRESH_TOKEN_ERROR = 'app/GoogleRedirectPage/CREATE_REFRESH_TOKEN_ERROR';



// ================ Reducer ================ //

const initialState = {
  meetingInProgress: false,
  meetingError: null,
  refreshTokenError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_MEETING_REQUEST:
      return { ...state, meetingInProgress: true, meetingError: null };
    case CREATE_MEETING_SUCCESS:
      return { ...state, meetingInProgress: false, meetingError: null };
    case CREATE_MEETING_ERROR:
      return { ...state, meetingInProgress: false, meetingError: payload };
    case CREATE_REFRESH_TOKEN_ERROR:
      return { ...state, refreshTokenError: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const createMeetingRequest = initialValues => ({
  type: CREATE_MEETING_REQUEST,
});

export const createMeetingSuccess = id => ({
  type: CREATE_MEETING_SUCCESS,
});

export const createMeetingError = e => ({
  type: CREATE_MEETING_ERROR,
  error: true,
  payload: e,
});
export const createRefreshTokenError = e => ({
  type: CREATE_REFRESH_TOKEN_ERROR,
  error: true,
  payload: e,
});


// ================ Thunks ================ //





export const getGoogleAccessToken = () => async (dispatch, getState, sdk) => {
  try {
    const getToken = await getAccessToken({});
    window.location.href = getToken;
  } catch (error) {
    // console.error(error,'error')
  }
}
export const refreshToken = (params) => async (dispatch, getState, sdk) => {

  const { currentUser } = getState().user;

  try {
    const getToken = await getRefreshToken({ ...params });
    const getListingParams = window.sessionStorage.getItem('params') && JSON.parse(window.sessionStorage.getItem('params'));
    const response = await sdk.transactions
      .show({
        id: getListingParams.id, include: [
          'customer',
          'provider',
        ],
      })
    const isCustomer = response?.data?.data.relationships.customer.data.id.uuid === currentUser.id.uuid

    window.location.href = isCustomer ? `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/order/${getListingParams.id}` : `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/sale/${getListingParams.id}`;
    // console.log(window.location,'location')
  } catch (error) {
    dispatch(createRefreshTokenError(storableError(error)));

    // console.error(error,'error')
  }
}
export const scheduleGoogleMeet = (params) => async (dispatch, getState, sdk) => {
  dispatch(createMeetingRequest());

  try {
    const scheduleMeeting = await scheduleGoogleMeeting(params);
    if (scheduleMeeting) {
      dispatch(createMeetingSuccess());
    }

  } catch (error) {
    dispatch(createMeetingError(storableError(e)));

    // console.error(error,'error')
  }
}




