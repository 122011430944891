import { useEffect } from 'react';

const TawkTo = ({ widgetId }) => {
  console.log(widgetId, 'widgetId');
  useEffect(() => {
    // Create a script element for the Tawk.to widget script
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.id = 'tawkToScript';
    scriptElement.src = `https://embed.tawk.to/${widgetId}`;

    // Append the script element to the document's head
    document.head.appendChild(scriptElement);

    // Remove the script element when the component unmounts
    return () => {
      const scriptElement = document.getElementById('tawkToScript');
      if (scriptElement) {
        scriptElement.remove();
      }
    };
  }, [widgetId]);

  return null;
};

export default TawkTo;
