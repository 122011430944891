import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.css';

const IconRadioButton = props => {
  const { checkedClassName, shape } = props;
  return (
    <div>
      {shape === 'square' ? <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={css.squareShapeIcon} style={{ fill: 'transparent' }}>
        <rect x="0.5" y="0.5" width="17" height="17" rx="4.5" stroke="#445463" className={classNames(css.checked, checkedClassName || css.checkedStyle)} />
      </svg> :
        <svg className={props.className} width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <circle
            className={props.showAsRequired ? css.required : css.notChecked}
            cx="7"
            cy="21"
            r="8"
            transform="translate(2 -12)"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          />

          <g
            className={classNames(css.checked, checkedClassName || css.checkedStyle)}
            transform="translate(2 -12)"
            fill="none"
            fillRule="evenodd"
          >
            <circle strokeWidth="2" cx="7" cy="21" r="8" />
            <circle fill="#FFF" fillRule="nonzero" cx="7" cy="21" r="1" />
          </g>
        </svg>}
    </div>
  );
};

IconRadioButton.defaultProps = { className: null };

IconRadioButton.propTypes = { className: string };

const FieldRadioButtonComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    checkedClassName,
    id,
    label,
    showAsRequired,
    shape,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.radioButtonWrapper}>
          <IconRadioButton
            className={svgClassName}
            checkedClassName={checkedClassName}
            showAsRequired={showAsRequired}
            shape={shape}
          />
        </span>
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldRadioButtonComponent;
