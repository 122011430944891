/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
*/

export const companyType = [
  {
    key: 'business',
    label: 'Business',
  },
  {
    key: 'consultancyFirm',
    label: 'Consultancy firm (tax, accounting or law firm)',
  },
];

export const hourOrDay = [
  {
    key: 'hour',
    label: 'Hour',
  },
  {
    key: 'day',
    label: 'Day',
  },
];

export const supportType = [
  {
    key: 'contractWork',
    label: 'Contract work / Project-based',
  },
  {
    key: 'interimRole',
    label: 'Interim roles',
  },
];
export const expectedDuration = [
  {
    key: '1Week3Months',
    label: '1 week – 3 months',
  },
  {
    key: '3Months6Months',
    label: '3 months – 6 months',
  },
  {
    key: '6Months',
    label: 'More than 6 months',
  },
];
export const professionalExperience = [
  {
    key: '5',
    label: '5',
  },
  {
    key: '10',
    label: '10',
  },
  {
    key: '15',
    label: '15',
  },
];

export const spokenLanguages = [
  {
    key: 'german',
    label: 'German',
  },
  {
    key: 'english',
    label: 'English',
  },
  {
    key: 'dutch',
    label: 'Dutch',
  },
  {
    key: 'french',
    label: 'French',
  },
  {
    key: 'spanish',
    label: 'Spanish',
  },
];

export const hourlyRatesRange = [
  {
    key: '50to75',
    label: '50-75',
  },
  {
    key: '75to100',
    label: '75-100',
  },
  {
    key: '100to125',
    label: '100-125',
  },
  {
    key: '125to150',
    label: '125-150',
  },
  {
    key: '150to200',
    label: '150-200',
  },
  {
    key: 'moreThan200',
    label: 'More then 200',
  },
];

export const availabilityTimezones = [
  {
    key: 'Europe/Western',
    label: 'Europe/Western',
  },
  {
    key: 'CET',
    label: 'Europe/Central',
  },
  {
    key: 'EET',
    label: 'Europe/Eastern',
  },
];

export const availableCountry = [
  {
    key: '50to75',
    label: 'Netherlands',
  },
  {
    key: '75to100',
    label: 'United Kingdom',
  },
  {
    key: '100to125',
    label: 'Germany',
  },
];
export const countrySupport = [
  {
    key: 'netherlands',
    label: 'Netherlands',
  },
  {
    key: 'unitedKingdom',
    label: 'United Kingdom',
  },
  {
    key: 'germany',
    label: 'Germany',
  },
  {
    key: 'luxembourg',
    label: 'Luxembourg',
  },
  {
    key: 'belgium',
    label: 'Belgium',
  },
  {
    key: 'remote',
    label: 'Remote',
  },
  {
    key: 'hybrid',
    label: 'Hybrid',
  },
];

export const txt = [
  
  { expertCountry: 'NL', clientCountry: 'LU', note: 'VAT reverse charge ' },
  { expertCountry: 'NL', clientCountry: 'DE', note: 'VAT reverse charge ' },
  { expertCountry: 'NL', clientCountry: 'BE', note: 'VAT reverse charge ' },
  { expertCountry: 'NL', clientCountry: 'GB', note: 'out of scope EU VAT' },

  { expertCountry: 'LU', clientCountry: 'NL', note: 'VAT reverse charge ' },
  { expertCountry: 'LU', clientCountry: 'DE', note: 'VAT reverse charge ' },
  { expertCountry: 'LU', clientCountry: 'BE', note: 'VAT reverse charge ' },
  { expertCountry: 'LU', clientCountry: 'GB', note: 'out of scope EU VAT' },

  { expertCountry: 'DE', clientCountry: 'LU', note: 'VAT reverse charge ' },
  { expertCountry: 'DE', clientCountry: 'NL', note: 'VAT reverse charge ' },
  { expertCountry: 'DE', clientCountry: 'BE', note: 'VAT reverse charge ' },
  { expertCountry: 'DE', clientCountry: 'GB', note: 'out of scope EU VAT' },
  

  { expertCountry: 'BE', clientCountry: 'LU', note: 'VAT reverse charge ' },
  { expertCountry: 'BE', clientCountry: 'DE', note: 'VAT reverse charge ' },
  { expertCountry: 'BE', clientCountry: 'NL', note: 'VAT reverse charge ' },
  { expertCountry: 'BE', clientCountry: 'GB', note: 'out of scope EU VAT' },

  { expertCountry: 'GB', clientCountry: 'LU', note: 'VAT reverse charge ' },
  { expertCountry: 'GB', clientCountry: 'DE', note: 'VAT reverse charge ' },
  { expertCountry: 'GB', clientCountry: 'NL', note: 'VAT reverse charge ' },
  { expertCountry: 'GB', clientCountry: 'BE', note: 'VAT reverse charge ' },
];

export const txtVat = [
  { taxableCountry: 'NL', clientCountry: 'LU', note: 'VAT reverse charge' },
  { taxableCountry: 'NL', clientCountry: 'DE', note: 'VAT reverse charge' },
  { taxableCountry: 'NL', clientCountry: 'BE', note: 'VAT reverse charge' },
  { taxableCountry: 'NL', clientCountry: 'GB', note: 'out of scope EU VAT' },
];

export const softwareKnowledge = [
  {
    key: 'sapS4hana',
    label: 'SAP S4hana',
  },
  {
    key: 'sapAriba ',
    label: 'SAP Ariba ',
  },
  {
    key: 'sapEcc',
    label: 'SAP ECC',
  },
  {
    key: 'sapConcur ',
    label: 'SAP Concur ',
  },
  {
    key: 'sapAnalyticsCloud ',
    label: 'SAP Analytics Cloud ',
  },
  {
    key: 'sapR3 ',
    label: 'SAP R/3',
  },
  {
    key: 'oracleCloudEpm',
    label: 'Oracle Cloud EPM ',
  },
  {
    key: 'oracleEbusinessSuiteR12 ',
    label: 'Oracle E-Business Suite R12',
  },
  {
    key: 'oracleFusion',
    label: 'Oracle Fusion',
  },
  {
    key: 'microsoftDynamics ',
    label: 'Microsoft Dynamics',
  },
  {
    key: 'jDEdwards',
    label: 'JDEdwards',
  },
  {
    key: 'vertex ',
    label: 'Vertex',
  },
  {
    key: 'avalara ',
    label: 'Avalara',
  },
  {
    key: 'sovos ',
    label: 'Sovos',
  },
  {
    key: 'cygnet',
    label: 'Cygnet',
  },
  {
    key: 'oneSource ',
    label: 'OneSource',
  },
  {
    key: 'powerBi ',
    label: 'Power Bi',
  },
  {
    key: 'tableau',
    label: 'Tableau',
  },
  {
    key: 'ai ',
    label: 'AI',
  },
  {
    key: 'rpaSoftware ',
    label: 'RPA software',
  },
  {
    key: 'alteryx ',
    label: 'Alteryx',
  },
  {
    key: 'hyperion',
    label: 'Hyperion',
  },
  {
    key: 'longview ',
    label: 'Longview',
  },
  {
    key: 'anaplan',
    label: 'Anaplan',
  },
  {
    key: 'navision',
    label: 'Navision',
  },
  {
    key: 'iScala',
    label: 'iScala',
  },
  {
    key: 'descartes ',
    label: 'Descartes',
  },
  {
    key: 'cargoWise',
    label: 'CargoWise',
  },
  {
    key: 'aeb ',
    label: 'AEB',
  },
  {
    key: 'gateway ',
    label: 'Gateway',
  },
  {
    key: 'portbase',
    label: 'Portbase',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const projectType = [
  {
    key: 'euVatCompliance',
    label: 'EU VAT Compliance',
  },
  {
    key: 'vatManagement',
    label: 'VAT Management',
  },
  {
    key: 'vatSoftwareImplementation',
    label: 'VAT Software Implementation',
  },
  {
    key: 'vatAdvisory',
    label: 'VAT Advisory',
  },
  {
    key: 'erpVatImplementation',
    label: 'ERP VAT implementation',
  },
  {
    key: 'vidaAnalysis',
    label: 'VIDA Analysis',
  },
  {
    key: 'eReportingAndEinvoicing',
    label: 'E-Reporting & E-invoicing',
  },
  {
    key: 'taxCompliance',
    label: 'Tax Compliance',
  },
  {
    key: 'taxAccounting',
    label: 'Tax Accounting',
  },
  {
    key: 'taxManagement',
    label: 'Tax Management',
  },
  {
    key: 'transferPricingManagement',
    label: 'Transfer Pricing Management',
  },
  {
    key: 'internationalTaxPlanning',
    label: 'International Tax Planning',
  },
  {
    key: 'bepsPillar2Management',
    label: 'BEPS Pillar 2 Management',
  },
  {
    key: 'taxTransformationProject',
    label: 'Tax transformation project',
  },
  {
    key: 'managementOfTaxAudits',
    label: 'Management of tax audits',
  },
  {
    key: 'corporateTaxAdvisory',
    label: 'Corporate Tax advisory',
  },
  {
    key: 'taxTechnologyDevelopment',
    label: 'Tax Technology Development',
  },
  {
    key: 'taxDataAnalytics',
    label: 'Tax Data Analytics',
  },
  {
    key: 'taxControlFramework',
    label: 'Tax Control Framework',
  },
  {
    key: 'taxAutomationProject',
    label: 'Tax Automation project',
  },
  {
    key: 'taxEngineImplementation',
    label: 'Tax Engine Implementation',
  },
  {
    key: 'm&ATaxProject',
    label: 'M&A Tax Project',
  },
  {
    key: 'trainingCourses',
    label: 'Training Courses',
  },
  {
    key: 'customsManagement',
    label: 'Customs Management',
  },
  {
    key: 'customsSoftwareImplementation',
    label: 'Customs Software Implementation',
  },
  {
    key: 'customsLicenseApplicationMaintenance',
    label: 'Customs License Application & Maintenance',
  },
  {
    key: 'customsValuationClassification',
    label: 'Customs Valuation & classification',
  },
  {
    key: 'customsAdvisory',
    label: 'Customs Advisory',
  },
  {
    key: 'exportControl',
    label: 'Export Control',
  },
  {
    key: 'tradeCompliance',
    label: 'Trade Compliance',
  },
  {
    key: 'import/exportDeclarations',
    label: 'Import/export declarations',
  },
];

export const interimRolePortfolio = [
  {
    key:'notApplicable',
    label:'Not applicable'
  },
  {
    key: 'vatDirector',
    label: 'VAT Director',
  },
  {
    key: 'taxDirector',
    label: 'Tax Director',
  },
  {
    key: 'customsDirector',
    label: 'Customs Director',
  },
  {
    key: 'groupTaxLead',
    label: 'Group Tax Lead',
  },
  {
    key: 'headOfTax',
    label: 'Head of Tax',
  },
  {
    key: 'vatManager',
    label: 'VAT Manager',
  },
  {
    key: 'taxManager',
    label: 'Tax Manager',
  },
  {
    key: 'customsManager',
    label: 'Customs Manager',
  },
  {
    key: 'managerExportControl',
    label: 'Manager Export Control',
  },
  {
    key: 'managerTradeCompliance',
    label: 'Manager Trade Compliance',
  },
  {
    key: 'taxTechnologyManager',
    label: 'Tax Technology Manager',
  },
  {
    key: 'taxControllerAccountant',
    label: 'Tax Controller/Accountant',
  },
  {
    key: 'vatAnalystConsultant',
    label: 'VAT Analyst/Consultant',
  },
  {
    key: 'taxAnalystConsultant',
    label: 'Tax Analyst/Consultant',
  },
  {
    key: 'customsConsultant',
    label: 'Customs Consultant',
  },
  {
    key: 'customsDeclarant',
    label: 'Customs declarant',
  },
];

export const interimRole = [
  {
    key: 'vatDirector',
    label: 'VAT Director',
  },
  {
    key: 'taxDirector',
    label: 'Tax Director',
  },
  {
    key: 'customsDirector',
    label: 'Customs Director',
  },
  {
    key: 'groupTaxLead',
    label: 'Group Tax Lead',
  },
  {
    key: 'headOfTax',
    label: 'Head of Tax',
  },
  {
    key: 'vatManager',
    label: 'VAT Manager',
  },
  {
    key: 'taxManager',
    label: 'Tax Manager',
  },
  {
    key: 'customsManager',
    label: 'Customs Manager',
  },
  {
    key: 'managerExportControl',
    label: 'Manager Export Control',
  },
  {
    key: 'managerTradeCompliance',
    label: 'Manager Trade Compliance',
  },
  {
    key: 'taxTechnologyManager',
    label: 'Tax Technology Manager',
  },
  {
    key: 'taxControllerAccountant',
    label: 'Tax Controller/Accountant',
  },
  {
    key: 'vatAnalystConsultant',
    label: 'VAT Analyst/Consultant',
  },
  {
    key: 'taxAnalystConsultant',
    label: 'Tax Analyst/Consultant',
  },
  {
    key: 'customsConsultant',
    label: 'Customs Consultant',
  },
  {
    key: 'customsDeclarant',
    label: 'Customs declarant',
  },
];

export const industryKnowledge = [
  {
    key: 'agriculture',
    label: 'Agriculture',
  },
  {
    key: 'energyIndustry',
    label: 'Energy industry',
  },
  {
    key: 'oilGas',
    label: 'Oil & Gas',
  },
  {
    key: 'waterWaste',
    label: 'Water & Waste',
  },
  {
    key: 'sustainabilityRecycling',
    label: 'Sustainability & Recycling',
  },
  {
    key: 'foodBeverageIndustry',
    label: 'Food & beverage industry',
  },
  {
    key: 'airTransportationTourism',
    label: 'Air transportation & Tourism',
  },
  {
    key: 'globalLogisticsSupplyChain',
    label: 'Global Logistics & Supply Chain',
  },

  {
    key: 'parcelExpressIndustry',
    label: 'Parcel & Express industry',
  },
  {
    key: 'aerospaceDefense',
    label: 'Aerospace & Defense',
  },
  {
    key: 'railPublicTransportation',
    label: 'Rail & Public Transportation',
  },
  {
    key: 'postalService',
    label: 'Postal service',
  },
  {
    key: 'packagingIndustry',
    label: 'Packaging industry',
  },
  {
    key: 'plantMachinery',
    label: 'Plant & Machinery',
  },
  {
    key: 'automotive',
    label: 'Automotive',
  },
  {
    key: 'constructionIndustry',
    label: 'Construction industry',
  },
  {
    key: 'semiconductorIndustry',
    label: 'Semiconductor industry',
  },

  {
    key: 'bankingM&A',
    label: 'Banking & M&A',
  },
  {
    key: 'privateEquity',
    label: 'Private Equity',
  },
  {
    key: 'financialServicesAccounting',
    label: 'Financial Services & Accounting',
  },
  {
    key: 'insurance',
    label: 'Insurance',
  },
  {
    key: 'professionalServiceFirms',
    label: 'Professional Service Firms',
  },
  {
    key: 'lawLegalIndustry',
    label: 'Law & Legal Industry',
  },
  {
    key: 'facilityServicesSecurity',
    label: 'Facility Services & Security',
  },
  {
    key: 'publicSectorGovernment',
    label: 'Public Sector & Government',
  },
  {
    key: 'biotechnology',
    label: 'Biotechnology',
  },


  {
    key: 'chemistry',
    label: 'Chemistry',
  },
  {
    key: 'medicalDevices',
    label: 'Medical Devices',
  },
  {
    key: 'healthcare',
    label: 'Healthcare',
  },
  {
    key: 'pharmaceuticalIndustry',
    label: 'Pharmaceutical industry',
  },
  {
    key: 'electronicEngineering',
    label: 'Electronic engineering',
  },
  {
    key: 'householdElectronics',
    label: 'Household Electronics',
  },
  {
    key: 'consumerGoods',
    label: 'Consumer Goods',
  },
  {
    key: 'wholesaleRetail',
    label: 'Wholesale & Retail',
  },
  {
    key: 'mediaEntertainment',
    label: 'Media & Entertainment',
  },


  {
    key: 'onlineMarketplaces',
    label: 'Online marketplaces',
  },
  {
    key: 'softwareItServices',
    label: 'Software & IT services',
  },
  {
    key: 'telecommunication',
    label: 'Telecommunication',
  },
  {
    key: 'realEstate',
    label: 'Real Estate',
  },
  {
    key: 'infrastructure',
    label: 'Infrastructure',
  },
];

export const listingFields = [
  {
    key: 'companyType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: companyType,
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Company Type',
      group: 'primary',
    },
    showConfig: {
      label: 'Company Type',
      isDetail: true,
    },
    saveConfig: {
      label: 'Company Type',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },

  // {
  //   key: 'supportType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: supportType,
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Support Type',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Support Type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Support Type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a Support Type.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

export const listingTypes = [
  // {
  //   listingType: 'daily-booking',
  //   label: 'Daily booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'day',
  //   },
  // },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  {
    listingType: 'hourly-booking',
    label: 'Hourly booking',
    transactionType: {
      process: 'proposal-process',
      alias: 'proposal-process/release-1',
      unitType: 'hour',
    },
  },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
