/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape, arrayOf, func } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../../util/reactIntl';
import { withViewport } from '../../../util/uiHelpers';

import { InlineTextButton, TabNav } from '../../../components';

import { createGlobalState } from './hookGlobalState';

import css from './LayoutSideNavigation.module.css';
import { logout } from '../../../ducks/auth.duck';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BILLING_ADDRESS_PAGE, CONTACT_PAGE, EXPERT_PORTFOLIO, PAYMENTMETHOD_PAGE, PORTFOLIO_TABS, PROFILE_SETTING, propTypes, STRIPE_PAYOUT_PAGE, USER_ROLE_EXPERT } from '../../../util/types';
import { pathByRouteName } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import appSettings from '../../../config/settings';
import IconCard, {
  ICON_USER_LOGOUT,
  ICON_USER_PAYMENT,
  ICON_USER_PRIVACY,
  ICON_USER_PROFILE,
  ICON_USER_PRORTFOLIO,
} from '../../IconCard/IconCard';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentPage, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentPage}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');
  useEffect(() => {
    const { currentPage, viewport } = props;

    let scrollTimeout = null;

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasHorizontalTabLayout) {
      scrollTimeout = window.setTimeout(() => {
        scrollToTab(currentPage, scrollLeft, setScrollLeft);
      }, 300);
    }

    return () => {
      // Update scroll position when unmounting
      const el = document.querySelector(`#${currentPage}Tab`);
      setScrollLeft(el?.parentElement.scrollLeft);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  });

  const { currentPage, currentUser } = props;
  const { role } = (currentUser?.id && currentUser?.attributes?.profile?.publicData) || {};

  const routeConfiguration = useRouteConfiguration();

  const handleLogout = () => {
    const { onLogout, history } = props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }
  
  const isTabSelected = role === USER_ROLE_EXPERT ? STRIPE_PAYOUT_PAGE : PAYMENTMETHOD_PAGE

  const tabs = [
    {
      text:<>
      <IconCard brand={ICON_USER_PROFILE}/>
      <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.ProfileSettingsPageTabTitle" />
        </>,
      selected: currentPage === PROFILE_SETTING,
      id: 'ProfileSettingsPageTab',
      linkProps: {
        name: PROFILE_SETTING,
      },
    },
    // {
    //   text:
    //   <>
    //   <IconCard brand={ICON_USER_PRORTFOLIO}/>
    //   <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.portfolioTabTitle" />
    //   </>,
    //   selected: currentPage === EXPERT_PORTFOLIO,
    //   id: 'ExpertPortfolioPageTab',
    //   linkProps: {
    //     name: EXPERT_PORTFOLIO,
    //   },
    // },
    {
      text: 
      <>
      <IconCard brand={ICON_USER_PRIVACY}/>
      <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />
      </>,
      selected: currentPage === CONTACT_PAGE,
      id: 'ContactDetailsPageTab',
      linkProps: {
        name: CONTACT_PAGE,
      },
    },
    {
      text:
      <>
      <IconCard brand={ICON_USER_PAYMENT}/>
      <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />
      </>,
      selected: currentPage === isTabSelected,
      id: role === USER_ROLE_EXPERT ? 'StripePayoutPageTab' :  'PaymentMethodsPageTab',
      linkProps: {
        name: role === USER_ROLE_EXPERT ? STRIPE_PAYOUT_PAGE : PAYMENTMETHOD_PAGE,
      },
    },
    // {
    //   text:
    //   <>
    //   <IconCard brand={ICON_USER_PAYMENT}/>
    //   <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.billingAddressTabTitle" />
    //   </>,
    //   selected: currentPage === BILLING_ADDRESS_PAGE,
    //   id:'BillingAddressPageTab',
    //   linkProps: {
    //     name: BILLING_ADDRESS_PAGE,
    //   },
    // },
  ];

  return (
    <>
      <TabNav
        rootClassName={css.tabs}
        tabRootClassName={css.tab}
        tabs={role === USER_ROLE_EXPERT ? tabs : tabs.filter(e => e.id != PORTFOLIO_TABS)}
      />
      {/* <InlineTextButton rootClassName={css.logoutButton} onClick={() => handleLogout()}>
        <IconCard brand={ICON_USER_LOGOUT} />
        <FormattedMessage id="TopbarDesktop.logout" />
      </InlineTextButton> */}
    </>
  );
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentPage: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentPage: string,
  onLogout: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
});

const LayoutWrapperAccountSettingsSideNav = compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LayoutWrapperAccountSettingsSideNavComponent);

export default LayoutWrapperAccountSettingsSideNav;
