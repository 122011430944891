/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.

  //PAYEMENT-PROCESS
  REQUEST_PAYMENT: 'transition/request-payment',
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  TRANSITION_COMPLETE_MILESTONE_REMINDER:'transition/complete-milestone-reminder',
  TRANSITION_PAYMENT_COMPLETE: 'transition/complete-payment',
  TRANSITION_PAYMENT_CANCEL: 'transition/cancel-payment',
  TRANSITION_PAYMENT_COMPLETE_AUTO: 'transition/auto-complete-payment',
  TRANSITION_COMPLETE_MILESTONE: 'transition/complete-milestone',
  TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT:'transition/complete-by-consultant',
  TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT_OPERATOR:'transition/operator-complete-as-consultant',
  TRANSITION_COMPLETE_PROJECT_BY_OPERATOR:'transition/operator-complete',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  //PROPOSAL-PROCESS
  REQUEST_PROPOSAL: 'transition/proposal-request',
  PROPOSAL_ACCEPTED: 'transition/proposal-accept',
  PROPOSAL_ACCEPTED_BY_EXPERT: 'transition/proposal-accept-by-expert',
  PROPOSAL_EXPIRE: 'transition/proposal-expire',
  PROPOSAL_DECLINE: 'transition/proposal-decline',
  PROPOSAL_CANCEL: 'transition/proposal-cancel',
  REQUEST_PROPOSAL_AFTER_DECLINE: 'transition/proposal-request-after-decline',
  PROPOSAL_UPDATE: 'transition/proposal-update',
  PROPOSAL_UPDATE_REQUEST_BY_CLIENT: 'transition/proposal-update-request-by-client',
  PROPOSAL_UPDATE_REQUEST_DECLINE: 'transition/proposal-update-request-decline',
  PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE: 'transition/proposal-update-request-after-decline',
  PROPOSAL_UPDATE_REQUEST_ACCEPT: 'transition/proposal-update-request-accept',
  // OPERATOR_ACCEPT_PROPOSAL:'transition/operator-proposal-accept-as-consultant',

  // MILESTONE TRANSITIONS
  TRANSITION_CREATE_MILESTONE: 'transition/milestone-added',
  TRANSITION_UPDATE_MILESTONE: 'transition/milestone-updated',
  TRANSITION_DELETE_MILESTONE: 'transition/milestone-deleted',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  AUTO_COMPLETE:'transition/auto-complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_MILESTONE_FIRST: 'pending-milestone-one',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_COMPLETED: 'payment-completed',
  COMPLETED: 'completed',
  CONFIRM_PENDING_PAYMENT: 'confirm-mark-completed',
  MILESTONE_FIRST: 'pending-milestone-one',
  PENDING_PAYMENT: 'pending-payment',
  PENDING_PROPOSAL: 'proposal-pending',
  PENDING_REQUEST: 'pending-request',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  DELIVERED: 'delivered',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  CANCELED_VIDEO_CHAT: 'cancel-video-chat',
  ACCEPTED_VIDEO_CHAT: 'accpet-video-chat',
  PROPOSAL_UPDATE_PENDING: 'proposal-update-pending',
  PROPOSAL_UPDATE_DECLINED: 'proposal-update-declined',
  PROPOSAL_UPDATE_ACCEPTED: 'proposal-update-accepted',
  STRIPE_PAYMENT_COMPLETED:'completed',
  STRIPE_PAYMENT_CANCEL:'canceled',
  MILESTONE_COMPLETED:'milestone-completed',
  PENDING_COMPLETION:'pending-completion',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'proposal-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PROPOSAL]: states.PENDING_PROPOSAL,
      },
    },
    [states.PENDING_PROPOSAL]: {
      on: {
        [transitions.PROPOSAL_DECLINE]: states.DECLINED,
        [transitions.PROPOSAL_EXPIRE]: states.EXPIRED,
        [transitions.PROPOSAL_CANCEL]: states.CANCELED,
        [transitions.PROPOSAL_UPDATE]: states.PENDING_PROPOSAL,
        [transitions.PROPOSAL_UPDATE_REQUEST_BY_CLIENT]: states.PROPOSAL_UPDATE_PENDING,
      },
    },
    [states.PROPOSAL_UPDATE_PENDING]: {
      on: {
        [transitions.PROPOSAL_UPDATE_REQUEST_DECLINE]: states.PROPOSAL_UPDATE_DECLINED,
        [transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT]: states.PENDING_PROPOSAL,
      }
    },
    [states.PROPOSAL_UPDATE_DECLINED]: {
      on: {
        [transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE]: states.PENDING_PROPOSAL,
      }
    },
    [states.DECLINED]: {
      on: {
        [transitions.REQUEST_PROPOSAL_AFTER_DECLINE]: states.PENDING_PROPOSAL
      }
    },
    [states.EXPIRED]: {},
    [states.CANCELED]: {},
    // [states.ACCEPTED]: {
    //   on: {
    //     [transitions.CANCEL]: states.CANCELED,
    //     [transitions.COMPLETE]: states.DELIVERED,
    //   },
    // },

    // [states.DELIVERED]: {
    //   on: {
    //     [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
    //     [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
    //     [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
    //   },
    // },

    // [states.REVIEWED_BY_CUSTOMER]: {
    //   on: {
    //     [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
    //     [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
    //   },
    // },
    // [states.REVIEWED_BY_PROVIDER]: {
    //   on: {
    //     [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
    //     [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
    //   },
    // },
  },
};
export const milestoneGraph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'milestone-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.PROPOSAL_ACCEPTED]: states.ACCEPTED,
        // [transitions.OPERATOR_ACCEPT_PROPOSAL]:states.ACCEPTED
      },
    },
    [states.ACCEPTED]: {
      on: {
        [transitions.TRANSITION_CREATE_MILESTONE]: states.ACCEPTED,
        [transitions.TRANSITION_UPDATE_MILESTONE]: states.ACCEPTED,
        [transitions.TRANSITION_DELETE_MILESTONE]: states.ACCEPTED,
        [transitions.TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT]:states.PENDING_COMPLETION,
        [transitions.TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT_OPERATOR]:states.PENDING_COMPLETION
        
      }
    },
    [states.PENDING_COMPLETION]:{
      on:{
        [transitions.COMPLETE]:states.COMPLETED,
        [transitions.AUTO_COMPLETE]:states.COMPLETED,
        [transitions.TRANSITION_COMPLETE_PROJECT_BY_OPERATOR]:states.COMPLETED
      }
    },

    [states.COMPLETED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },

  },
};

export const paymentGraph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'payment-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.CONFIRM_PAYMENT]: states.PAYMENT_COMPLETED,
        [transitions.EXPIRE_PAYMENT]: states.EXPIRED,
      }
    },
    [states.EXPIRED]: {},
    [states.PAYMENT_COMPLETED]: {
      on: {
        [transitions.TRANSITION_COMPLETE_MILESTONE]:states.MILESTONE_COMPLETED,
        [transitions.TRANSITION_COMPLETE_MILESTONE_REMINDER]:states.PAYMENT_COMPLETED,
      }
    },
    [states.MILESTONE_COMPLETED]: {
      on: {
        [transitions.TRANSITION_PAYMENT_COMPLETE]:states.STRIPE_PAYMENT_COMPLETED,
        [transitions.TRANSITION_PAYMENT_CANCEL]:states.STRIPE_PAYMENT_CANCEL,
        [transitions.TRANSITION_PAYMENT_COMPLETE_AUTO]:states.STRIPE_PAYMENT_COMPLETED,
      }
    },
    [states.STRIPE_PAYMENT_CANCEL]: {},
    [states.STRIPE_PAYMENT_COMPLETED]: { type: 'final' }
  }
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.REQUEST_PROPOSAL,
    transitions.REQUEST_PROPOSAL_AFTER_DECLINE,
    transitions.PROPOSAL_ACCEPTED_BY_EXPERT,
    transitions.PROPOSAL_UPDATE,
    transitions.PROPOSAL_UPDATE_REQUEST_ACCEPT,
    transitions.PROPOSAL_UPDATE_REQUEST_AFTER_DECLINE,
    transitions.TRANSITION_CREATE_MILESTONE,
    transitions.TRANSITION_UPDATE_MILESTONE,
    transitions.TRANSITION_DELETE_MILESTONE,
    transitions.OPERATOR_ACCEPT,
    transitions.PROPOSAL_UPDATE_REQUEST_BY_CLIENT,
    transitions.PROPOSAL_UPDATE_REQUEST_DECLINE,
    transitions.PROPOSAL_ACCEPTED,
    transitions.PROPOSAL_DECLINE,
    transitions.TRANSITION_COMPLETE_PROJECT_BY_OPERATOR,
    transitions.TRANSITION_COMPLETE_PROJECT_BY_CONSULTANT_OPERATOR,
    transitions.PROPOSAL_EXPIRE,
    transitions.PROPOSAL_CANCEL,
    transitions.CANCEL,
    transitions.COMPLETE,
    transitions.AUTO_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
    transition
  );
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.DECLINE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED];
