import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess, MILESTONE_PROCESS_NAME, PROPOSAL_PROCESS_NAME } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';
import { transitions } from '../../transactions/transactionProcessBooking';
import { ACTIVE_TAB, CANCEL_TAB, CLIENT_LISTING_TYPE, USER_ROLE_CLIENT } from '../../util/types';
import { getLastTransitions, getUserRole } from '../../util/dataExtractor';
import { USER_ROLE_EXPERT } from '../../util/types';
import { fetchCurrentUser, fetchCurrentUserHasListings, fetchCurrentUserNotifications } from '../../ducks/user.duck';
const { UUID } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/DashboardPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_LISTINGS_SUCCESS = 'app/DashboardPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/DashboardPage/FETCH_OWN_LISTINGS_SUCCESS';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  getListings: [],
  getOwnListings: [],
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, getListings: payload, fetchInProgress: false, }
    case FETCH_OWN_LISTINGS_SUCCESS:
      return { ...state, fetchInProgress: false, getOwnListings: payload }

    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: response,
});

const fetchOwnListingsSuccess = response => ({
  type: FETCH_OWN_LISTINGS_SUCCESS,
  payload: response,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const fetchTransactions = (params, search) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const currentUser = getState().user.currentUser;
    const userRole = getUserRole(currentUser);
    const apiQueryParams = {
      only: params.tab === ACTIVE_TAB && userRole === USER_ROLE_EXPERT ?
        'sale'
        : params.tab === ACTIVE_TAB && userRole === USER_ROLE_CLIENT ?
          'order'
          : null,
      lastTransitions: getLastTransitions(params),
      processName: params.tab === ACTIVE_TAB ?
        MILESTONE_PROCESS_NAME
        : PROPOSAL_PROCESS_NAME,
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
        'protectedData',
        'metadata',
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
      perPage: INBOX_PAGE_SIZE,
    };

    await sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        const data = response?.data?.data || [];
        const promises = [];
        data.forEach(transaction => {
          const previoustxId = transaction?.attributes?.protectedData?.previoustxId;
          const apiQueryParams = {
            id: new UUID(previoustxId), page,
            perPage: INBOX_PAGE_SIZE
          };

          if (previoustxId && params.tab === ACTIVE_TAB) {
            sdk.transactions.show(apiQueryParams).then(childTransaction => {
              Object.assign(transaction.attributes, {
                transitions: [
                  ...transaction.attributes.transitions,
                  ...childTransaction?.data?.data?.attributes?.transitions,
                ].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()),
              });
            })
          }
        })
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));
        dispatch(fetchCurrentUserNotifications());
        return response;
      })
      .catch(e => {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      });
  } catch (error) {
    console.log(error)
  }
}



export const fetchListings = (params, config) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchOrdersOrSalesRequest());
    const currentUser = getState().user.currentUser;
    const userRole = getUserRole(currentUser);
    dispatch(fetchCurrentUser());

    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config?.layout?.listingImage || {};

    const aspectRatio = aspectHeight / aspectWidth;

    if (params?.tab === CANCEL_TAB) {
      const response = await sdk.listings.query({
        pub_listingTypes: userRole === USER_ROLE_EXPERT ? CLIENT_LISTING_TYPE : null,
        include: ['author', 'author.profileImage', 'author.profile',],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      }
      )
      dispatch(addMarketplaceEntities(response));
      const matchedListings = response.data.data.map(listing => (listing.id))
      dispatch(fetchListingsSuccess(matchedListings));
    }

  } catch (error) {
    console.log(error)
  }

}
const RESULT_PAGE_SIZE = 10;
export const fetchOwnListings = (params) => async (dispatch, getState, sdk) => {
  try {
    const page = 1;
    dispatch(fetchOrdersOrSalesRequest());
    if (params?.tab === CANCEL_TAB) {
      const queryParams = {
        page,
        perPage: RESULT_PAGE_SIZE,
      }
      const response = await sdk.ownListings.query(queryParams);

      dispatch(fetchOwnListingsSuccess(response?.data?.data))
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export const fetchUserTransactions = (userId) => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.transactions.query({ userId });
    return response

  } catch (error) {
    console.log(error)
  }
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchTransactions(params, search)),
    dispatch(fetchListings(params, config)),
    dispatch(fetchOwnListings(params)),
  ])

};
