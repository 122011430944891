import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { USER_ROLE_CLIENT, USER_ROLE_EXPERT, propTypes, PROFILE_SETTINGS_PATH_NAME, ACTIVE_TAB } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
  IconCard,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { getUserRole } from '../../../util/dataExtractor';
import { ICON_USER_LOGOUT, ICON_USER_PROFILE, NOTIFICATION_ICON } from '../../IconCard/IconCard';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    location,
  } = props;
  const [mounted, setMounted] = useState(false);
  const userRole = getUserRole(currentUser);
  const emailUnverified = !!currentUser?.id && !currentUser.attributes.emailVerified;
  const userId = currentUser && currentUser.id && currentUser.id.uuid;
  const { publicData, bio } = currentUser?.id && currentUser?.attributes?.profile || {};
  const { companyName } = publicData || {};

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab:  userRole !== USER_ROLE_EXPERT ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <IconCard brand={NOTIFICATION_ICON} />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          {userId && <NamedLink
            className={emailUnverified ? classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'),css.menuItem,css.pointerEvents): classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'),css.menuItem)}
            name="ProfilePage"
            params={{ id: userId }}
          >
            <span className={css.menuItemBorder} />
            <IconCard brand={ICON_USER_PROFILE}/>
            <FormattedMessage id="TopbarDesktop.profile" />
          </NamedLink>}
        </MenuItem>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={classNames(css.logoutButton,css.menuItem)} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <IconCard brand={ICON_USER_LOGOUT} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const clientSignupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.projectSubmitLink} to={{ client: true }}>
      <FormattedMessage id="TopbarDesktop.clientSignup" />
    </NamedLink>
  );
  const expertSignupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarDesktop.expertSignup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.navContent}>
        <LinkedLogo
          className={css.logoLink}
          format="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        />
        {/* {search} */}
        <div className={css.linkWrapper}>
          {isAuthenticatedOrJustHydrated && userRole === USER_ROLE_CLIENT ? (
            <></>
            // <NamedLink className={classNames(css.submitProjectButton)} name="SearchPage">
            //   <FormattedMessage id="TopbarDesktop.findAConsultant" />
            // </NamedLink>
          ) : null

          }
          {isAuthenticatedOrJustHydrated && userRole === USER_ROLE_CLIENT && companyName ? (
            <NamedLink className={classNames(css.submitProjectButton)} name="NewListingPage" >
              <Button><FormattedMessage id="TopbarDesktop.submitNewProjects" /></Button>
            </NamedLink>
          ) :
            isAuthenticatedOrJustHydrated && userRole === USER_ROLE_EXPERT ? (
              <></>
              // <NamedLink className={classNames(css.createListing)} name="ExpertJobSearchPage">
              //   <FormattedMessage id="TopbarDesktop.findAJob" />
              // </NamedLink>
            )
              :
              null}
          {userRole === USER_ROLE_EXPERT ?
            <>
              {isAuthenticated && userId && currentUserHasListings ?
                <NamedLink name="ProfilePage" params={{ id: userId }} className={css.completeProfile}>
                  <FormattedMessage id="TopbarDesktop.publishedProfile" />
                </NamedLink>
                :
                <NamedLink name="ProfileSettingsPage"className={emailUnverified ? css.pointerEvents :  css.completeProfile} >
                  <FormattedMessage id="TopbarDesktop.clientProfile" />
                </NamedLink>

              }
            </>
            :
            <>
              {isAuthenticated && userId && !companyName && location.pathname !== PROFILE_SETTINGS_PATH_NAME ?
                <NamedLink name="ProfileSettingsPage" className={emailUnverified ? css.pointerEvents :  css.completeProfile}>
                  <FormattedMessage id="TopbarDesktop.clientProfile" />
                </NamedLink>
                :
                null
              }
            </>
          }
          {isAuthenticated ?
            <NamedLink name="DashboardPage" params={{ tab: ACTIVE_TAB }} className={emailUnverified ? css.pointerEvents :  css.completeProfile}> 
              <FormattedMessage id="TopbarDesktop.dashboard" />
            </NamedLink>
            :
            null
          }
          {/* {inboxLink} */}
          {/* {isAuthenticated ? (
            <div className={css.profileAvatar}>
              <Avatar className={css.avatar} user={currentUser} />
            </div>
          ) : null} */}
          {profileMenu}
          {clientSignupLink}
          {expertSignupLink}
          {loginLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
