import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import { useParams } from 'react-router';

import css from './UserNav.module.css';
import { ACTIVE_TAB, CANCEL_TAB, COMPLETED_TAB, MESSAGE_TAB, PROPOSAL_TAB, USER_ROLE_EXPERT } from '../../util/types';
import { getUserRole } from '../../util/dataExtractor';
import { transitions } from '../../transactions/transactionProcessBooking';

const UserNav = props => {
  const { className, rootClassName, currentUser, providerNotificationCount, readCount } = props;

  const classes = classNames(rootClassName || css.root, className);
  const params = useParams()
  const tabs = [
    {
      text: (
        <span className={classNames(params.tab == ACTIVE_TAB && css.active)}>
          <FormattedMessage id="DashboardPage.activeText" />
        </span>
      ),
      linkProps: {
        name: 'DashboardPage',
        params: { tab: ACTIVE_TAB },
      },
    },
    {
      text: (
        <span className={classNames(params.tab == CANCEL_TAB && css.active)}>
          <FormattedMessage id={getUserRole(currentUser) === USER_ROLE_EXPERT ? "DashboardPage.cancelText" : "DashboardPage.clientText"} />
        </span>
      ),
      linkProps: {
        name: 'DashboardPage',
        params: { tab: CANCEL_TAB },
      },
    },
    {
      text: (
        <span className={classNames(params.tab == PROPOSAL_TAB && css.active)}>
          <FormattedMessage id={getUserRole(currentUser) === USER_ROLE_EXPERT ? "DashboardPage.proposalText" : "DashboardPage.proposalRecivedText"} />
        </span>
      ),
      linkProps: {
        name: 'DashboardPage',
        params: { tab: PROPOSAL_TAB },
      },
    },
    {
      text: (
        <span className={classNames(params.tab == COMPLETED_TAB && css.active)}>
          <FormattedMessage id="DashboardPage.completedText" />
        </span>
      ),
      linkProps: {
        name: 'DashboardPage',
        params: { tab: COMPLETED_TAB },
      },
    },
    {
      text: (
        <span className={classNames(params.tab == MESSAGE_TAB && css.active)}>
          <FormattedMessage id="DashboardPage.messageText" />{" "}<span className={css.unreadMessages}>{parseInt(providerNotificationCount - readCount)}</span>
        </span>
      ),
      linkProps: {
        name: 'DashboardPage',
        params: { tab: MESSAGE_TAB },
      },
    },
    // {
    //   text: <FormattedMessage id="UserNav.accountSettings" />,
    //   selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
    //   disabled: false,
    //   linkProps: {
    //     name: 'ContactDetailsPage',
    //   },
    // },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
